import { BidIncrement } from '@shared/generated/models/bid-increment';
import { LotDto } from '@shared/generated/models/lot-dto';
import { round } from 'inova-shared';
import { ActivatedRoute, Router } from '@angular/router';
import { MediaType } from '@shared/generated/models/media-type';
import { MediaObjectDto } from '@shared/generated/models/media-object-dto';
import { Type } from '@angular/core';

export function createJsonPatchPath(path: string, id?: number): string {
  return '/' + (id ? id + '/' : '') + path.replace(/\./g, '/');
}

export function navigateToNewCulture(router: Router, culture: string): Promise<boolean> {
  const route = router.url.split('/');
  route.splice(0, 2);
  return router.navigateByUrl('/' + culture.toLowerCase() + '/' + route.join('/'), { replaceUrl: true });
}

export function calculateValidBidAmountSuggestions(lot: LotDto, bidIncrements: BidIncrement[], minBid: number, count: number = 30): number[] {
  const startingBid = lot.startingBid ?? 0;
  bidIncrements = bidIncrements.sort((a, b) => a.from > b.from ? -1 : 1);

  let currentBid = Math.max(minBid, startingBid);
  const previousBid = getPreviousBid(currentBid, bidIncrements);
  let result = [];
  for (let i = 0; i < count; i++) {
    const nextBid = getNextBid(currentBid, i === 0 && currentBid > 0, bidIncrements);
    result.push(nextBid);
    currentBid = nextBid;
  }

  if (startingBid && startingBid <= previousBid) {
    result = [previousBid, ...result];
  }
  return result.map((r) => round(r, 2));
}

function getPreviousBid(currentBid: number, bidIncrements: BidIncrement[]): number {
  // above max-bid-increment-from? save some computations and calculate from max-bid-increment onwards
  const maxBidIncrement = bidIncrements[0];
  if (currentBid > maxBidIncrement.from) {
    const diff = currentBid - maxBidIncrement.from;
    return (Math.ceil(diff / maxBidIncrement.increase) - 1) * maxBidIncrement.increase + maxBidIncrement.from;
  }
  // below max-bid-increment-from? -> iterate through start to end
  let runner = 0;
  let bidIncrement = 0;
  while (runner + bidIncrement < currentBid) {
    runner += bidIncrement;
    bidIncrement = getCurrentBidIncrement(runner, bidIncrements).increase;
  }
  return runner;
}

function getNextBid(currentBid: number, includeCurrent: boolean, bidIncrements: BidIncrement[]): number {
  // above max-bid-increment-from? save some computations and calculate from max-bid-increment onwards
  const maxBidIncrement = bidIncrements[0];
  if (currentBid > maxBidIncrement.from) {
    const diff = currentBid - maxBidIncrement.from;
    const steps = diff / maxBidIncrement.increase;
    const bidExactlyOnStep = steps % 1 === 0;
    return (Math.ceil(steps) + (bidExactlyOnStep ? includeCurrent ? 0 : 1 : 0)) * maxBidIncrement.increase + maxBidIncrement.from;
  }
  // below max-bid-increment-from? -> iterate through start to end
  let runner = 0;
  while ((includeCurrent && runner < currentBid) || (!includeCurrent && runner <= currentBid)) {
    runner += getCurrentBidIncrement(runner, bidIncrements).increase;
  }
  return runner;
}

function getCurrentBidIncrement(currentBid: number, sortedBidIncrements: BidIncrement[]): BidIncrement {
  // TODO handle error-case when biddingStep with from === 0 does not exist
  return sortedBidIncrements.find((s) => s.from <= currentBid)!;
}

export function scrollToElementId(id: string, document: Document, smooth: boolean = true): Promise<void> {
  return new Promise((resolve, reject) => {
    const element = document.getElementById(id);
    if (!element) {
      reject(new Error('Element not found'));
      return;
    }
    const header = document.getElementById('auex-header');
    let headerOffset = 20;
    if (header && getComputedStyle(header).position === 'fixed') {
      headerOffset += header.clientHeight;
    }
    const elementPosition = element.getBoundingClientRect().top;
    const offsetPosition = elementPosition + window.scrollY - headerOffset;

    window.scrollTo({
      top: offsetPosition,
      behavior: smooth ? 'smooth' : 'auto'
    });

    // Listening to the scroll event
    const scrollListener = () => {
      if (Math.abs(window.pageYOffset - offsetPosition) < 1) {
        window.removeEventListener('scroll', scrollListener);
        resolve();
      }
    };

    // Attaching listener
    window.addEventListener('scroll', scrollListener);

    // In case of instant scroll or small distance, the scroll event may not fire, so check manually after some delay
    setTimeout(scrollListener, 100);
  });
}

export function elementIsInViewPort(id: string, document: Document): boolean {
  const itemElement = document.getElementById(id) as HTMLElement | undefined;
  if (!itemElement) {
    return false;
  }
  const itemRect = itemElement.getBoundingClientRect();
  const viewportHeight = window.innerHeight;
  return itemRect.top >= 0 && itemRect.top < viewportHeight;
}

export function cleanNumericRequestParams<T>(params: any, maxNumber: number = 2147483645): T {
  Object.keys(params as object).forEach((key) => {
    const param = params[key];
    if (!isNaN(param) && param > maxNumber) {
      params[key] = maxNumber;
    }
  });
  return params;
}

interface QueryParams {
  [key: string]: string | number | boolean
}

export function appendQueryParams(url: string, queryParams: QueryParams): string {
// Use a temporary base URL if the provided URL is relative.
  const tempBaseUrl = 'https://example.com';
  const isRelative = !url.startsWith('http://') && !url.startsWith('https://');
  const fullUrl = isRelative ? tempBaseUrl + url : url;

  const urlObj = new URL(fullUrl);
  const currentSearch = urlObj.search;

  let queryParamsString = currentSearch.startsWith('?') ? currentSearch.substring(1) : currentSearch;

  for (const key in queryParams) {
    if (Object.prototype.hasOwnProperty.call(queryParams, key)) {
      queryParamsString += (queryParamsString ? '&' : '') + key + '=' + queryParams[key];
    }
  }

  urlObj.search = queryParamsString;

  if (isRelative) {
    // Remove the temporary base URL to get the relative URL.
    return urlObj.toString().substring(tempBaseUrl.length);
  } else {
    return urlObj.toString();
  }
}


export function buildMediaGallery(mediaGallery: MediaObjectDto[], placeHolderPath: string, onlyPlaceholder: boolean): MediaCollection {
  const placeholder = {
    contentType: '',
    mediaType: MediaType.Image,
    title: 'placeholder',
    fileName: 'placeholder',
    fileSize: 0,
    binaryRefId: '',
    href: placeHolderPath
  };
  const images = mediaGallery.filter((m) => m.mediaType === MediaType.Image).map((m) => ({
    ...m,
    href: buildBinaryHref(m)
  }));
  return {
    images: onlyPlaceholder || !images.length ? [placeholder] : images,
    videos: onlyPlaceholder ? [] : mediaGallery.filter((m) => m.mediaType === MediaType.Video).map((m) => ({
      ...m,
      href: buildBinaryHref(m)
    })),
    docs: [], // TODO onlyPlaceholder ? [] : mediaGallery.filter((m) => m.mediaType === MediaType.Video).map((m) => ({...m, href: buildBinaryHref(m)})),
    placeHolderImage: onlyPlaceholder
  };
}

export function buildBinaryHref(media: MediaObjectDto): string {
  return `/b/${media.binaryRefId}/${media.fileName}`;
}


export interface MediaWithHref extends MediaObjectDto {
  href: string
}

export interface MediaCollection {
  images: MediaWithHref[],
  videos: MediaWithHref[],
  docs: MediaWithHref[],
  placeHolderImage: boolean
}

export function getLastRoutingComponent(activatedRoute: ActivatedRoute): Type<unknown> | null {
  let runner = activatedRoute.firstChild ?? null;
  while (runner?.firstChild) {
    runner = runner.firstChild;
  }
  return runner?.component ?? null;
}

export function waitForElement(selector: string, document: Document, timeout: number = 3000): Promise<Element> {
  return new Promise((resolve, reject) => {
    const observer = new MutationObserver((mutationsList, obs) => {
      // Look through all mutations that just occurred
      mutationsList = mutationsList.filter((ml) => ml.addedNodes.length);
      for (const mutation of mutationsList) {
        const element = document.querySelector(selector);
        if (element) {
          obs.disconnect();
          clearTimeout(timeoutId);
          resolve(element);
          return;
        }
      }
    });

    // Start observing the document with the configured parameters
    observer.observe(document, { attributes: false, childList: true, subtree: true });

    const timeoutId = setTimeout(() => {
      observer.disconnect();
      reject(new Error(`Timeout waiting for element with selector "${selector}"`));
    }, timeout);

    // Check for the element immediately in case it's already present
    const existingElement = document.querySelector(selector);
    if (existingElement) {
      observer.disconnect();
      clearTimeout(timeoutId);
      resolve(existingElement);
    }
  });
}
